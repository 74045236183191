<template>
    <div class="icon" :class="{btn: href !== ''}" @click="jump">
        <svg class="svg">
            <use :xlink:href="`#${name}`" />
        </svg>
    </div>
</template>

<script setup lang="ts">
    import { Router } from 'vue-router';

    // 用於存放元件的 props
    const props = defineProps({
        name: { type: String, required: true, default: 'circle' },
        href: { type: String, default: '' },

    });

    let router: Router | null = null;
    if(props.href !== ''){
        router = useRouter(); // 操作路由
    }

    // 跳轉
    const jump = () => {
        if (props.href !== '' && router) {
            router.push(props.href);
        }
    };
</script>

<style lang="scss">
    .icon {
        --cubeColor: var(--themeColor);

        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        @include setSize(100%, 100%);
        fill: #bbb;
        overflow: hidden;
        transition: 0.2s $cubic-FiSo;
        .svg {
            display: flex;
            align-items: center;
            justify-content: center;
            @include setSize(100%, 100%);
        }

        &.btn {
            cursor: pointer;
            &.cube {
                fill: #f2f2f2;
                background: $colorMain;
                @include setSize(50px, 50px);
                padding: 10px;
                border-radius: 15px;
                opacity: 0.8;
            }

            &:hover,
            &.current {
                fill: $colorMain;
            }
        }

        &.cube {
            fill: #f2f2f2;
            background: var(--cubeColor);
            @include setSize(50px, 50px);
            padding: 10px;
            border-radius: 20px;
            opacity: .8;
            cursor: pointer;
            &:hover{ transform: scale3d(1.1, 1.1, 1); }
        }
    }
</style>
