<script setup lang="ts">
    import { storeToRefs } from 'pinia';
    import piniaStore from '@/store';

    const userStore = piniaStore.useUserStore;
    const { userState } = storeToRefs(userStore);

    const filterUrl = () => {
        // 用正則判斷
        // 取得圖片、登入、token驗證 不觸發
        const reg = /\/api\/image\/d+|\/oauth\/token/;

        return (url: string) => {
            return !reg.test(url);
        };
    };
</script>

<template>
    <div class="app" :class="{ isLogin: userState.isLogin }">
        <q-ajax-bar
            :hijack-filter="filterUrl()"
            size="5px"
        ></q-ajax-bar>

        <OrgaHeader></OrgaHeader>

        <transition name="fadeY" mode="out-in" appear>
            <OrgaNav v-if="userState.isLogin"></OrgaNav>
        </transition>

        <section class="contentBlock" :class="{login: !userState.isLogin}"> <!-- v-if="states.user.isLogin"-->
            <!-- <transition name="zoom" mode="out-in">
                <div class="loadingMask" v-show="elStatusState.isLoading"></div>
            </transition> -->

            <router-view v-slot="{ Component }">
                <transition name="zoom" mode="out-in">
                    <component :is="Component"></component>
                </transition>
            </router-view>
        </section>
    </div>
</template>

<style lang="scss">
    //  載入基礎樣式
    // vue 漸變樣式
    @import '@/assets/scss/vueTransition.scss';

    // 基礎元件與行為
    @import '@/assets/scss/base.scss';

    .app {
        @include setFlex(flex-start, center, 0, column);
        @include setSize(100%, 100%);
        @include setScroll();
        padding-right: 40px;
        padding-left: 40px;
        overflow: auto;
        &.isLogin {
            .featuresBlock {
                width: 25%;
                max-width: 300px;
            }
            .viewBlock {
                @include setSize(100%, 100%);
            }
        }

        &:not(.isLogin){
            @include setRWD(600px) {
                padding-right: 0;
                padding-left: 0;
            };
        }

        @include setRWD(1800px) {
            padding-right: 30px;
            padding-left: 30px;
        };
        @include setRWD(1024px) {
            padding-right: 20px;
            padding-left: 20px;
        };
        @include setRWD(600px) {
            padding-right: 10px;
            padding-left: 10px;
        };

        .q-loading-bar {
            background: #31726e;
        }
    }

    .navBlock {
        @extend %scope;
        margin-top: 10px;
        padding-left: 0;
    }

    .contentBlock {
        flex-grow: 1;
        position: relative;
        background: #fff;
        @extend %scope;
        // min-height: calc(100vh - 265px);
        padding-top: 40px;
        padding-bottom: 40px;

        border-radius: 35px 50px 50px;

        margin-bottom: 40px;
        &.login {
            flex: 1;
            background: $colorBack;
            margin-bottom: 0;
        }

        .loadingMask {
            position: absolute;
            top: 0;
            left: 0;
            background: #fff;
            @include setSize(100%, 100%);
            z-index: 1;
        }
    }
</style>
